import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "lemajic_best_2019" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Клиент Catenaccio Sports Agency <Link to="/players/lemajic">Дарко Лемаич</Link> был признан лучшим игроком 2019 года в Латвийской Высшей Лиге. В прошедшем сезоне Дарко забил 15 голов и стал лучшим бомбардиром чемпионата. Замечательный результат, Дарко, поздравляем!</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/j2wExJFpuZQ"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}